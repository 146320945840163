<template>
    <modal-manager-login ref="managerlogin" @loggedin="createZreport()" />

    <a class="cursor-pointer text-white" @click="open()">
        <svg v-if="!mobile" class="h-6 h-6" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M3 3a1 1 0 011 1v12a1 1 0 11-2 0V4a1 1 0 011-1zm7.707 3.293a1 1 0 010 1.414L9.414 9H17a1 1 0 110 2H9.414l1.293 1.293a1 1 0 01-1.414 1.414l-3-3a1 1 0 010-1.414l3-3a1 1 0 011.414 0z" clip-rule="evenodd"/>
        </svg>
        <a v-else class="block px-3 py-2 rounded-md text-base font-medium button-wide button-filled button">Uitloggen</a>
    </a>

    <div v-if="active_opentables" class="fixed z-10 flex top-0 h-full items-center inset-x-0 px-4 pb-4 sm:inset-0 sm:flex sm:items-center sm:justify-center ">
        <div class="fixed inset-0 transition-opacity" @click="close()">
            <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <div id="modal" class="mobile-y-compensator rounded-sm px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full sm:p-6" role="dialog" aria-modal="true" aria-labelledby="modal-headline">

            <modal-close @close="close()"/>

            <div>
                <div class="mt-3 text-left sm:mt-5">
                    <h3 class="modal-title text-lg leading-6 font-bold text-2xl mb-2" id="modal-headline">
                       {{$t('translations.components.modal-logout.title')}}
                    </h3>

                    <p v-if="terminal.table_forceclose_required">{{$t('translations.components.modal-logout.ask-to-close-tables')}}</p>
                    <p v-else>{{$t('translations.components.modal-logout.warn-for-open-tables')}}</p>

                    <router-link v-if="terminal.table_forceclose_required" :to="{ name: 'pos.tables', query:{ opentables: 1 } }" class="mt-5 button button-wide button-filled button-large">
                        {{$t('translations.components.modal-logout.Go-to-tables')}}
                    </router-link>
                </div>
            </div>
            <div v-if="!terminal.table_forceclose_required" class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                <span class="flex w-full rounded-sm  shadow-sm sm:col-start-2">
                    <a @click="logoutWithoutClosingTables()" class="button button-large button-danger button-wide">
                        {{$t('translations.components.modal-logout.confirm-logout')}}
                    </a>
                </span>
                <span class="mt-3 flex w-full rounded-sm  shadow-sm sm:mt-0 sm:col-start-1">
                    <router-link :to="{ name: 'pos.tables', query:{ opentables: 1 } }" class="button button-large button-filled w-full">
                        {{$t('translations.components.modal-logout.cancel-go-to-tables')}}
                    </router-link>
                </span>
            </div>
        </div>
    </div>

    <div v-if="active" class="fixed z-10  inset-x-0 px-4 pb-4 sm:inset-0 sm:flex sm:items-center sm:justify-center">
        <div class="fixed inset-0 transition-opacity">
            <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <div v-if="activeZreport" id="modal" class="rounded-sm px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full sm:p-6 z-50" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
            <modal-close @close="close()"/>
            <div>
              <div class="mt-3 text-center sm:mt-5">
                <h3 class="modal-title leading-6 font-medium" id="modal-headline">
                  {{$t('translations.components.modal-logout.create-z-rapport')}}
                </h3>
                <div class="mt-2 py-5">
                  <p class="text-sm leading-5">
                    {{$t('translations.components.modal-logout.ask-for-z-rapport')}}
                  </p>
                </div>
                <date-picker v-model="bookingsdate" color="gray" is-dark />
              </div>
            </div>
            <div class="mt-5 button-large flex">
                <a @click="canCreateZreport()" class="button button-filled button-large flex-grow mr-4">
                    {{$t('translations.components.modal-logout.yes')}}
                </a>
                <a @click="logout()" class="button button-large button-ghost flex-grow">
                    {{$t('translations.components.modal-logout.no')}}
                </a>
            </div>
        </div>
        <div v-else class="h-50vh flex items-center justify-center">
          <loading-indicator />
        </div>

    </div>

    <div v-if="active_confirmation" class="fixed z-10 bottom-0 inset-x-0 px-4 pb-4 sm:inset-0 sm:flex sm:items-center sm:justify-center">
        <div class="fixed inset-0 transition-opacity">
            <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <div id="modal" class="rounded-sm px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full sm:p-6" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
            <div>
                <div class="mt-3 sm:mt-5">
                    <h3 class="modal-title leading-6 font-medium" id="modal-headline">
                        {{$t('translations.components.modal-logout.ask-if-z-rapport-printed')}}
                    </h3>
                </div>
            </div>
            <div class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                <span class="flex w-full rounded-sm  shadow-sm sm:col-start-2">
                    <a @click="logout()" class="button button-large button-ghost button-wide">
                        {{$t('translations.components.modal-logout.confirm-logout')}}
                    </a>
                </span>
                <span class="mt-3 flex w-full rounded-sm  shadow-sm sm:mt-0 sm:col-start-1">
                    <a @click="printZreport()" class="button button-large button-danger">
                        {{$t('translations.components.modal-logout.print-not-successful')}}
                    </a>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
// import moment from 'moment';

import * as _printerqueue from '../lib/printerqueue.js';

import { DatePicker } from 'v-calendar';
import 'v-calendar/dist/style.css';

// prints
import PrintReport from '../prints/report';

// components
import ModalClose from "./ModalClose";
import ModalManagerLogin from "./ModalManagerLogin";
import LoadingIndicator from "@/components/LoadingIndicator.vue"

export default {
    props: ['mobile'],
    components: {
        LoadingIndicator,
        DatePicker,
        ModalClose,
        ModalManagerLogin,
    },
    data () {
        return {
            active: false,
            active_confirmation: false,
            active_opentables: false,
            zreport: null,
            bookingsdate: new Date(),
            activeZreport: true
        }
    },
    methods: {
        open() {
            this.checkOpenTables()
        },
        close() {
            this.active = false
            this.active_opentables = false
            this.active_confirmation = false
        },
        openConfirmation() {
            this.active_confirmation = true
        },
        closeConfirmation() {
            this.active_confirmation = false
        },

        async logout() {

            this.$store.dispatch('location/clear')
            this.$store.dispatch('payment/clear')
            this.$store.dispatch('printer/clear')
            this.$store.dispatch('terminal/clear')
            this.$store.dispatch('transaction/clear')
            this.$store.dispatch('transaction/setClerk', null)
            this.activeZreport = false

            _printerqueue.clear();

            localStorage.clear();

            await axios.post('/logout', {}).finally(() => {
                this.$store.dispatch('auth/logout')

                window.location.href = '/login'
            })
        },

        canCreateZreport() {
            this.$refs.managerlogin.open()
        },

        async createZreport() {
            await axios.post('/zreport', {
                bookingsdate: this.bookingsdate
            }).then(response => {
                this.zreport = response.data
                this.printZreport()
            }).catch(() => {
                this.$notify({ group: "notifications", type: 'error', title: "Fout opgetreden", text: "Oeps er ging iets fout.." }, 2000);
            }).finally(() => {
                this.close()
                this.openConfirmation()
                // this.logout()
            })
        },

        async checkOpenTables() {
            await axios.get('/tables?open=1&open_allowed=1', {}).then(response => {
                if (response.data.data.length > 0) {
                    this.active_opentables = true;
                }
                else {
                    this.active = true
                }
            })
        },

        logoutWithoutClosingTables() {
            this.active_opentables = false;
            this.active = true;
        },

        printZreport() {
            new PrintReport().print('zreport', this.zreport)
        }
    },
	computed: {
		isMobile() {
			return this.$store.getters['terminal/isMobile'];
        },
		terminal() {
			return this.$store.getters['terminal/terminal'];
        },
    }
}
</script>
